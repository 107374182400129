/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-loops */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import Loader from 'Component/Loader';
import {
    MyAccountOverlayComponent as SourceMyAccountOverlayComponent,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.component';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';
import Overlay from 'SourceComponent/Overlay';
import { ReactElement } from 'SourceType/Common.type';

import './MyAccountOverlay.style';

/** @namespace Inov8/Component/MyAccountOverlay/Component */
export class MyAccountOverlayComponent extends SourceMyAccountOverlayComponent {
    overLayRef: any;

    renderMyAccount(): ReactElement {
        const { state } = this.props;
        const { render } = this.renderMap[state];

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <div block="MyAccountOverlay" elem="Action" mods={ { state } }>
                <button block="MyAccountOverlay" elem="Close-Button" onClick={ this.onCloseButtonClick }>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="12px"
                  viewBox="0 0 329.26933 329"
                  width="12px"
                >
                    <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
                </svg>

                </button>
                { render() }
            </div>
        );
    }

    onCloseButtonClick(): void {
        const overlayElements = document.getElementsByClassName('MyAccountOverlay-Wrapper');
        for (let i = 0; i < overlayElements.length; i++) {
            const element = overlayElements[i] as HTMLElement;
            element.remove();
        }
    }

    render(): ReactElement {
        const {
            onVisible,
            isCheckout,
            isMobile,
            isLoading,
        } = this.props;

        return (
            <div
              block="MyAccountOverlay"
              elem="Wrapper"
            >
                <Overlay
                  id={ CUSTOMER_ACCOUNT_OVERLAY_KEY }
                  mix={ { block: 'MyAccountOverlay' } }
                  onVisible={ onVisible }
                  isStatic={ !isCheckout && isMobile }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderMyAccount() }
                </Overlay>
            </div>
        );
    }
}

export default MyAccountOverlayComponent;
