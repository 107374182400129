import {
    NewsletterSubscriptionComponent as SourceNewsletterSubscriptionComponent,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';
import { ReactElement } from 'SourceType/Common.type';

import './NewsletterSubscription.style';

/** @namespace Inov8/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscriptionComponent {
    render(): ReactElement {
        return (
            <div block="NewsletterSubscription">
                <h3 block="NewsletterSubscription" elem="Title">
                    { __('Join The INOV8 Community') }
                </h3>
                <p block="NewsletterSubscription" elem="Subtitle">
                    { __('Subscribe today and get access to exclusive drops and events from INOV8 and much more.') }
                </p>
                { super.render() }
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
