import {
    FieldSelectComponent as SourceFieldSelectComponent,
} from 'SourceComponent/FieldSelect/FieldSelect.component';
import { ReactElement } from 'SourceType/Common.type';
import { Option } from 'SourceType/Field.type';

import './FieldSelect.style';

/** @namespace Inov8/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelectComponent {
    renderOption(option: Option): ReactElement {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true,
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            attr,
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: attr.id === 'subscription_frequency' || attr.id === 'subscription_tier' ? false : !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered,
              } }
              key={ id }
              /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? 0 : -1 }
            >
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    }
}

export default FieldSelectComponent;
