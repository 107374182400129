/* eslint-disable max-lines */
import {
    NEWSLETTER_COLUMN as SOURCE_NEWSLETTER_COLUMN,
    RENDER_NEWSLETTER as SOURCE_RENDER_NEWSLETTER,
} from 'SourceComponent/Footer/Footer.config';

// TODO: implement RENDER_NEWSLETTER
export const RENDER_NEWSLETTER = SOURCE_RENDER_NEWSLETTER;

// TODO: implement NEWSLETTER_COLUMN
export const NEWSLETTER_COLUMN = SOURCE_NEWSLETTER_COLUMN;

// TODO: implement COLUMN_MAP
export const COLUMN_MAP = [
    {
        title: __('Discover INOV8'),
        items: [
            {
                href: '/about-us',
                title: __('About Us'),
            },
            {
                href: '/jobs',
                title: __('Careers'),
            },
            {
                href: '/press-releases',
                title: __('Press'),
            },
            // {
            //     href: '/affiliate-hub',
            //     title: __('Affiliates'),
            // },
            {
                href: '/stores',
                title: __('Stores'),
            },
        ],
    },
    {
        title: __('Our Community'),
        items: [
            {
                href: '/blog/lake-district/wainwrights',
                title: __('Walk The Wainwrights'),
            },
            {
                href: '/lakeland-trails',
                title: __('Lakeland Trails'),
            },
            {
                href: '/blog',
                title: __('Inspiration Hub'),
            },
        ],
    },
    {
        title: __('Our Responsibility'),
        items: [
            {
                href: '/sustainability',
                title: __('Sustainability'),
            },
            {
                href: '/sustainability/renov8',
                title: __('RENOV8'),
            },
        ],
    },
    {
        title: __('How Can We Help?'),
        items: [
            {
                href: '/size-guide',
                title: __('Size Guide'),
            },
            {
                href: '/shipping',
                title: __('Shipping'),
            },
            {
                href: '/returns',
                title: __('Returns'),
            },
            {
                href: '/contact',
                title: __('Contact Us'),
            },
            // {
            //     href: '/faq',
            //     title: __('FAQ'),
            // },
            {
                href: '/price-match-promise',
                title: __('Price Match Promise'),
            },
        ],
    },
];

export const LEGAL_LINKS = [
    {
        href: '/legal/privacy-policy',
        title: __('Privacy'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: '/legal/terms-and-conditions',
        title: __('Terms & Conditions'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: '/legal/terms-and-conditions-of-use',
        title: __('Terms & Conditions of Use'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: '/legal/trademarks',
        title: __('Trademarks'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: '/distributors',
        title: __('Distributors'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: '/legal/cookie-policy',
        title: __('Cookies'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: '/sitemap_uk.xml',
        title: __('Site Map'),
        available_in: [
            'uk',
        ],
    },
    {
        href: '/sitemap_eu.xml',
        title: __('Site Map'),
        available_in: [
            'eu',
        ],
    },
    {
        href: '/sitemap_us.xml',
        title: __('Site Map'),
        available_in: [
            'us',
        ],
    },
    {
        href: '/sitemap_au.xml',
        title: __('Site Map'),
        available_in: [
            'au',
        ],
    },
    {
        href: '/sitemap_ca.xml',
        title: __('Site Map'),
        available_in: [
            'ca',
        ],
    },
    {
        href: '/sitemap_no.xml',
        title: __('Site Map'),
        available_in: [
            'no',
        ],
    },
    {
        href: '/sitemap_ch.xml',
        title: __('Site Map'),
        available_in: [
            'ch',
        ],
    },
];

export const SOCIALS = [
    {
        href: 'https://www.instagram.com/inov_8/',
        src: 'footer/socials/instagram.png',
        title: __('Instagram'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: 'https://www.facebook.com/INOV8run/',
        src: 'footer/socials/facebook.png',
        title: __('Facebook'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: 'https://twitter.com/inov_8',
        src: 'footer/socials/x.png',
        title: __('X'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: 'https://www.youtube.com/@inov8__',
        src: 'footer/socials/youtube.png',
        title: __('YouTube'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: 'https://www.strava.com/clubs/146080?hl=en-GB',
        src: 'footer/socials/strava.png',
        title: __('Strava'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
    {
        href: 'https://www.tiktok.com/@inov8_',
        src: 'footer/socials/tiktok.png',
        title: __('TikTok'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'row',
            'ch',
            'no',
        ],
    },
];

export const PAYMENT_METHODS = [
    {
        src: 'footer/payment_methods/mastercard.png',
        title: __('Master Card'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/maestro.png',
        title: __('Maestro'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/visa.png',
        title: __('Visa'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/amex.png',
        title: __('American Express'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/apple.png',
        title: __('Apple Pay'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/gpay.png',
        title: __('Google Pay'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/clearpay.png',
        title: __('Clearpay'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/klarna.png',
        title: __('Klarna'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/amazon.png',
        title: __('Amazon Pay'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/paypal.png',
        title: __('PayPal'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
];

type StoreViews = {
    [key: string]: string;
};

// Define the SITES array

export const SITES: StoreViews = {
    uk: 'https://www.inov8.com/',
    us: 'https://www.inov8.com/us/',
    eu: 'https://www.inov8.com/eu/',
    row: 'https://www.inov8.com/row/',
    au: 'https://www.inov8.com/au/',
    ca: 'https://www.inov8.com/ca/',
    no: 'https://www.inov8.com/no/',
    ch: 'https://www.inov8.com/ch/',
};

export const STORE_VIEW_IMAGES = {
    uk: 'greatbritain.png',
    us: 'usa.png',
    eu: 'euro.png',
    row: 'euro.png',
    au: 'australia.png',
    ca: 'canada.png',
    no: 'euro.png',
    ch: 'euro.png',
};

export const COUNTRIES = [
    {
        label: __('Australia'), value: 'au', country_code: 'au', backgroundPosition: '0 -1088px',
    },
    {
        label: __('Austria'), value: 'at', country_code: 'at', backgroundPosition: '0 -1056px',
    },
    {
        label: __('Belgium'), value: 'be', country_code: 'be', backgroundPosition: '0 -1312px',
    },
    {
        label: __('Bulgaria'), value: 'bg', country_code: 'bg', backgroundPosition: '0 -1376px',
    },
    {
        label: __('Canada'), value: 'ca', country_code: 'ca', backgroundPosition: '0 -1792px',
    },
    {
        label: __('Croatia'), value: 'hr', country_code: 'hr', backgroundPosition: '0 -3488px',
    },
    {
        label: __('Denmark'), value: 'dk', country_code: 'dk', backgroundPosition: '0 -2368px',
    },
    {
        label: __('Estonia'), value: 'ee', country_code: 'ee', backgroundPosition: '0 -2528px',
    },
    {
        label: __('Finland'), value: 'fi', country_code: 'fi', backgroundPosition: '0 -2720px',
    },
    {
        label: __('France'), value: 'fr', country_code: 'fr', backgroundPosition: '0 -2848px',
    },
    {
        label: __('Germany'), value: 'de', country_code: 'de', backgroundPosition: '0 -2304px',
    },
    {
        label: __('Greece'), value: 'gr', country_code: 'gr', backgroundPosition: '0 -3264px',
    },
    {
        label: __('Hungary'), value: 'hu', country_code: 'hu', backgroundPosition: '0 -3552px',
    },
    {
        label: __('Ireland'), value: 'ie', country_code: 'ie', backgroundPosition: '0 -3616px',
    },
    {
        label: __('Italy'), value: 'it', country_code: 'it', backgroundPosition: '0 -3840px',
    },
    {
        label: __('Latvia'), value: 'lv', country_code: 'lv', backgroundPosition: '0 -4640px',
    },
    {
        label: __('Lithuania'), value: 'lt', country_code: 'lt', backgroundPosition: '0 -4576px',
    },
    {
        label: __('Luxembourg'), value: 'lu', country_code: 'lu', backgroundPosition: '0 -4608px',
    },
    {
        label: __('Monaco'), value: 'mc', country_code: 'mc', backgroundPosition: '0 -3584px',
    },
    {
        label: __('Netherlands'), value: 'nl', country_code: 'nl', backgroundPosition: '0 -5504px',
    },
    {
        label: __('Norway'), value: 'no', country_code: 'no', backgroundPosition: '0 -5536px',
    },
    {
        label: __('Poland'), value: 'pl', country_code: 'pl', backgroundPosition: '0 -5888px',
    },
    {
        label: __('Portugal'), value: 'pt', country_code: 'pt', backgroundPosition: '0 -5984px',
    },
    {
        label: __('Romania'), value: 'ro', country_code: 'ro', backgroundPosition: '0 -6144px',
    },
    {
        label: __('San Marino'), value: 'sm', country_code: 'sm', backgroundPosition: '0 -6560px',
    },
    {
        label: __('Slovakia'), value: 'sk', country_code: 'sk', backgroundPosition: '0 -6496px',
    },
    {
        label: __('Slovenia'), value: 'si', country_code: 'si', backgroundPosition: '0 -6464px',
    },
    {
        label: __('Spain'), value: 'es', country_code: 'es', backgroundPosition: '0 -2656px',
    },
    {
        label: __('Sweden'), value: 'se', country_code: 'se', backgroundPosition: '0 -6400px',
    },
    {
        label: __('Switzerland'), value: 'ch', country_code: 'ch', backgroundPosition: '0 -1920px',
    },
    {
        label: __('United Kingdom'), value: 'uk', country_code: 'uk', backgroundPosition: '0 -2912px',
    },
    {
        label: __('United States'), value: 'us', country_code: 'us', backgroundPosition: '0 -7328px',
    },
    {
        label: __('Rest of the world'), value: 'row', country_code: 'row', backgroundPosition: '0 0',
    },
];

export interface CountryOption {
    readonly value: string;
    readonly label: string;
    readonly src: string;
}
